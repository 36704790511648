html,body 
{
  height: 100%;
  margin: 0px; 
}

body{
  background-color: darkgray;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  font-size: 4px;
  text-align: left;
}

.env{
  margin: auto;
  margin-top: 3%;
  width: 60%;
}

#envelope{

  background-color: white;
  border: 5px solid black;
}

div{
    border: 1px solid rgba(255, 0, 0, 0);
    overflow-wrap: break-word;
}

foreignObject
{
    border: 1px solid rgba(0, 0, 255, 0.05);
}

#stamp
{
    border: none;
}


.bigText{
  

  font-size: 10em;
}